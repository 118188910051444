import React from "react";
import boba1 from "../image/boba-4.png"
import boba2 from "../image/boba-wip.png"
import {Link} from "react-router-dom";

function Boba() {
  return (
    <>
      <div className='frame'>
        <div className="head">
          <Link to="/home" id="back">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
          </Link>
          <div className="info">
            <h1>Currently Working on: It's Boba Time Concept</h1>
            <p>For my favorite boba shop, made in Blender 3D.</p>
          </div>
        </div>

        <div className="gallery-wrapper">
          <img src={boba2} alt="3D Blender Cycles - Cozy Bedroom"></img>
          <img src={boba1} alt="3D Blender Cycles - Cozy Bedroom"></img>
          
        </div>
          

        
      </div>
    </>
  );
}

export default Boba;